jQuery(document).ready(function ($) {
    $('#mobile-menu-button').click(function () {
        $('#menu-main-menu').toggleClass('active');
        $('#tickets').toggleClass('active');
        $('#mobile-menu-overlay').toggleClass('active');
    });
    $('#site-navigation .menu-item-has-children >a').append('<span class="chevron"></span>');
    $('body').on('click', '#site-navigation .menu-item-has-children >a .chevron', function (e) {
        if ($(window).width() < 1200) {
            e.preventDefault();
            $(this).closest('.menu-item-has-children').toggleClass('active');
            var smenu = $(this).closest('.menu-item-has-children').find('.sub-menu');
            smenu.toggleClass('active');
        }
    });
    $('#mobile-menu-overlay').click(function () {
        $('#menu-main-menu').toggleClass('active');
        $('#tickets').toggleClass('active');
        $(this).toggleClass('active');
    });
});
